import { BlockStack, Button, InlineGrid, Modal, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import DatePicker from '@/components/DatePicker';
import Layout from '@/components/Layout';
import dashboardPageSlice, { datePickerSelector, titleDatePickerSelector } from '@/redux/features/dashboard.slice';
import OrderStatistics from './components/OrderStatistics';
import ShipmentsOvertime from './components/ShipmentsOvertime';
import YourAccount from './components/YourAccount';
import RecentActivities from './components/RecentActivities';
import { useProcessOldOrderMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import HeadingBanner from './components/HeadingBanner';
import RecommendedApps from './components/RecommendedApps';
import OverQuotaBanner from './components/OverQuotaBanner';
import HelpCenter from './components/HelpCenter';
// import CrossSellReturnBanner from '@/components/CrossSellReturnBanner';
import { tokenSelector } from '@/redux/features/auth.slice';
import Bfcm2024 from './components/Bfcm2024';
// import FeatureBanner from './components/FeatureBanner';

const Dashboard = () => {
  const [modalState, setModalState] = useState<{ isOpen: boolean; value: string }>({
    isOpen: false,
    value: '30',
  });
  const [apiError, setApiError] = useState<string>('');
  const datePicker = useSelector(datePickerSelector);
  const titleDatePicker = useSelector(titleDatePickerSelector);
  const { shop } = useSelector(tokenSelector);
  const shopName = shop.split('.')[0] || '';
  const dispatch = useDispatch();

  const [resyncOrder, { isLoading }] = useProcessOldOrderMutation();

  const handleToggleModal = () => setModalState((state) => ({ ...state, isOpen: !state.isOpen }));
  const handleCloseModal = () => {
    setApiError('');
    setModalState((state) => ({ ...state, value: '30', isOpen: false }));
  };
  const handleChangeInputNumber = (value: string) => {
    setApiError('');
    setModalState((state) => ({ ...state, value: Math.min(+value, 60).toString() }));
  };

  const handleApplyDatePicker = (start: Date, end: Date) => dispatch(dashboardPageSlice.actions.handleDatePicker({ start, end }));
  const handleSaveDatePickerTitle = (titleBtn: string) => dispatch(dashboardPageSlice.actions.handleTitleDatePicker(titleBtn));

  const handleProcessOldOrder = () => {
    resyncOrder({ rangeDay: Number(modalState.value) }).then((data: any) => {
      if (data?.data?.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <Layout
      title={`Hello ${shopName},`}
      subtitle="Welcome to Synctrack Order Tracking 🎉"
      primaryAction={
        <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
          <DatePicker
            titleBtn={titleDatePicker}
            {...datePicker}
            onSave={handleApplyDatePicker}
            setTitleBtn={handleSaveDatePickerTitle}
          />
          <Button variant="primary" onClick={handleToggleModal}>
            Get old order
          </Button>
        </div>
      }
      secondaryActions={<YourAccount />}
    >
      {/* <CrossSellReturnBanner /> */}
      {/* <FeatureBanner /> */}
      <Bfcm2024 />
      <HeadingBanner />
      <OverQuotaBanner />
      <BlockStack gap="400">
        <OrderStatistics />
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
          <ShipmentsOvertime />
          <RecentActivities />
        </InlineGrid>
        <HelpCenter />
        <RecommendedApps />
      </BlockStack>

      <Modal
        open={modalState.isOpen}
        onClose={handleCloseModal}
        title="Would you like to process the old orders?"
        primaryAction={{
          content: 'OK',
          onAction: handleProcessOldOrder,
          disabled: modalState.value === '0',
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Process the old orders in last"
            type="number"
            value={JSON.stringify(Math.floor(Math.abs(Number(modalState.value))))}
            onChange={handleChangeInputNumber}
            autoComplete="off"
            max={60}
            suffix={Number(modalState.value) > 1 ? 'days' : 'day'}
            error={apiError}
          />
        </Modal.Section>
      </Modal>
    </Layout>
  );
};

export default Dashboard;
